import styled, { css } from 'styled-components';
import { grayColor, whiteColor } from 'components/styles/constants';
import { Container } from '@material-ui/core';
import { colouredBackground } from 'components/styles/util';


export const ContainerStyled = styled(Container)`
  z-index: 2;
  position: relative;
`
export const FadeBlockWrapper = styled.div`
  position: relative;
  display: block;

  background-size: cover;
  background-position: center;
  
  text-align: center;
  ${({ color, intensity }) => css`
    color: ${color ? whiteColor : grayColor[1]};
    ${color && colouredBackground(color, intensity)}
  `}

  ${({ image }) => image ? css`
    background-image: url(${image});
  ` : ''}

  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
  padding: 1rem;
  width: 80%;


  & .MuiContainer-root {
    padding: 0;
    overflow: auto;
    max-height: 70vh;
  }

  & p {
    font-size: inherit;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      font-size: 1.4rem;
      line-height: 1.6rem;
      margin: 0 1rem 1rem;
      padding: 2rem;
    }
  `}
`