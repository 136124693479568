import React from "react";
import { GridContainerStyled, GridItemStyled } from "components/Layout/styled";

export default ({ variant = 'default', children = null, columns = [], split = 12, md = 12, sm = 12}) => {

  const small = variant==='small'

  return (
    <GridContainerStyled small={small}>
      { children && 
        <GridItemStyled
        md={md}
        sm={sm}>
        { children }
        </GridItemStyled>}
      { columns.map((item, i) => {
        return (
          <GridItemStyled key={i}
            md={split}
            sm={split}>{ item }
          </GridItemStyled>
          )
        })
      }
    </GridContainerStyled>
  )
}
