import styled, { css } from 'styled-components';
import GridContainer from "components/core/Grid/GridContainer.js";
import GridItem from "components/core/Grid/GridItem.js";
import { blackColor, grayColor } from 'components/styles/constants';
import { hexToRgb } from 'components/styles/util';

export const GridContainerStyled = styled(GridContainer)`
  text-align: center;

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      margin: 1rem 0;
    }
  `}
  & h2 {
    color: ${grayColor[1]};
  }
  & h4 {
    color: ${grayColor[1]};
  }
  & p {
    color: ${grayColor[7]};
  }
`
export const GridItemStyled = styled(GridItem)`
  margin-left: auto;
  margin-right: auto;
`
export const ImgStyled = styled.img`
  width: 100%;
  min-height: 10rem;
  display: block;

  ${({ round }) => round && css`
    border-radius: 6px;
  `}
  ${({ circle }) => circle && css`
    border-radius: 50%;
  `}
  ${({ raised }) => raised && css`
    box-shadow: 0 5px 15px -8px rgba(${hexToRgb(blackColor)}, 0.24),
      0 8px 10px -5px rgba(${hexToRgb(blackColor)}, 0.2);
  `}
`
export const GridTwoItemsStyled = styled(GridItemStyled).attrs(({
  md: 6,
  sm: 6
}))``
