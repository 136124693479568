import styled, { css } from 'styled-components';
import GridContainer from "components/core/Grid/GridContainer.js";
import { grayColor } from 'components/styles/constants';
import { Logo } from 'views/Logo/Logo';
import { commonCss } from 'components/styles/util';



export const FooterContainerStyled = styled(GridContainer)`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  & h4, & h5 {
    text-decoration: none;
  }
  & small {
    font-size: 75%;
    color: ${grayColor[10]};
  }
`
export const FooterTitleStyled = styled.h5`
  ${commonCss.breakpointDown('xs')`
    text-align: center;
  `}
`
export const FooterWrapperStyled = styled.div`
  &:before {
    content: '';
    display: block;
    width: 80%;
    height: 1px;
    background-color: ${grayColor[0]};
    margin: 0 auto;
  }
`
export const LogoFooterStyled = styled(Logo)`
  & svg, img {
    height: 3rem;
  }

  
  
  & h1 {
    color: inherit;
    font-size: 1.4rem;
  }
`
export const SocialFeedStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 1rem;
  display: grid;

  & li a {
    margin: 0 1rem;
    display: flex;
    flex-direction: row;
  }
  & p {
    margin-left: 1rem;
  }
  & i {
    font-size: 20px;
    width: auto;
  }

`
export const WrapperLogoStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`
export const VerticalListStyled = styled.ul`
  & li {
    display: block !important;
    margin-left: -5px;
    margin-right: -5px;
    & a {
      padding: 5px !important;
    }
  }
`
