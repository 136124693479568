import styled, { css } from 'styled-components';
import { whiteColor } from 'components/styles/constants';
import { Grid } from '@material-ui/core';
import { colouredBackground } from 'components/styles/util';

export const HeroWrapper = styled.article`
  position: relative;
  display: block;
  color: ${whiteColor};

  background-size: cover;
  background-position: center;

  padding: 0.8rem;
  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      padding: 2rem;
    }
  `}
  
  text-align: center;
  ${({ color, intensity, shade }) => color && colouredBackground(color, intensity, shade)}
  ${({ image }) => image ? css`
    background-image: url(${image});
  ` : ''}
`

export const GridStyled = styled(Grid)`
  z-index: 4;
  position: relative;

  display: flex;
  align-items: center;
  height: 50%;

  & .MuiGrid-item {
    width: 100%;
  }
`