/*eslint-disable*/
import React from "react";

import Footer from "components/core/Footer/Footer.js";
import { FooterContainerStyled, FooterWrapperStyled, LogoFooterStyled, SocialFeedStyled, VerticalListStyled, WrapperLogoStyled } from "./footer/styled";
import { GenericButton } from "components/core/CustomButtons/styled";
import Box from "components/layout/Box";
import { Link } from "gatsby";

export default function SectionFooter({ hideButton = false }) {
  return (
    <FooterWrapperStyled>
      { !hideButton && <Box>
        <Link to='/contacto'>
          <GenericButton color='primary'>Contáctame</GenericButton>
        </Link>
      </Box>}
      <Footer theme='dark' content=''>
        <FooterContainerStyled>
          <div>
            <WrapperLogoStyled>
              <LogoFooterStyled />
            </WrapperLogoStyled>
          </div>
          <div>
            <FooterContact />
          </div>
        </FooterContainerStyled>
      </Footer>
    </FooterWrapperStyled>
  );
}


const FooterContact = () => (
  <>
    <SocialFeedStyled>
      <li>
        <a href="tel:622 31 28 48">
          <i className="fa fa-phone" />
          <p>622 31 28 48 / 910 93 53 53</p>
        </a>
      </li>
      <li>
        <a href="mailto:gestion@alianzas.info">
          <i className="fa fa-envelope" />
          <p>gestion@alianzas.info</p>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/ali.an.zas/">
          <i className="fab fa-instagram" />
          <p>instagram.com/ali.an.zas</p>
        </a>
      </li>
      <li>
        <a href="https://www.google.com/maps/place/Calle+Real,+9,+28991+Torrej%C3%B3n+de+la+Calzada,+Madrid/">
          <i className="fa fa-map-marker-alt" />
          <p>C/ Real, 9. Local 12 - Torrejón de la Calzada (Madrid)</p>
        </a>
      </li>
    </SocialFeedStyled>
  </>
)
