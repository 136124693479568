import styled, { css } from 'styled-components';
import { Theme } from '@material-ui/core/styles';
import { Link as LinkGatsby } from 'gatsby';

export const LogoWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  &:hover, &:focus {
  }
  & h1 {
    margin: 0;
  }
`;
export const LogoLeftStyled = styled.div`
  display: flex;
  svg, img {
    flex-grow: 1;
  }
`;

export const LinkLogo = styled(LinkGatsby)<{ theme: Theme }>`
  text-decoration: none;
  button {
    min-width: 0;
  }
  &:hover {
    text-decoration: none;
  }
`;
