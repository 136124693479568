import { blackColor, dangerColor, grayColor, infoColor, primaryColor, roseColor, successColor, warningColor } from 'components/styles/constants';
import { css } from 'styled-components';

export const commonCss = {
  breakpointDown: point => str => ({ theme }) => 
  css`
      ${theme.breakpoints.down(point)}{
        ${str}
      }
    `,
  iconLeftRight: css`
    & svg:first-child {
      margin-right: 0.5rem;
    }
    & svg:last-child {
      margin-left: 0.5rem;
    }
  `,
  centerFlex: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `
};

export const hexToRgb = input => {
  input = input + "";
  input = input.replace("#", "");
  
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    console.error(input, "input is not a valid hex color.");
    return '0,0,0'
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
};

export const lighten = (hex, percent = 100) => {
  return `rgba(${hexToRgb(hex)}, ${percent / 100})`;
};

export const px2rem = (px) => Number(px) ? `${Number(px) / 16}rem` : 0;

const RGB_Linear_Shade=(p: number, c: string)=>{
  var i=parseInt,r=Math.round,[a,b,c,d]=c.split(","),P=Number(p<0),t=P?0:255*p,P=P?1+p:1-p;
  return"rgb"+(d?"a(":"(")+r(i(a[3]=="a"?a.slice(5):a.slice(4))*P+t)+","+r(i(b)*P+t)+","+r(i(c)*P+t)+(d?","+d:")");
}

const backgroundColors = {
  primary: { a: primaryColor[4], b: primaryColor[5] },
  rose: { a: roseColor[3], b: roseColor[4] },
  info: { a: infoColor[6], b: infoColor[7] },
  success: { a: successColor[6], b: successColor[7] },
  warning: { a: warningColor[6], b: warningColor[7] },
  danger: { a: dangerColor[6], b: dangerColor[7] },
  black: { a: blackColor, b: grayColor[2] },
}
enum Intensity {
  strong = 'strong',
  default = 'default',
  soft = 'soft',
}
export const colouredBackground = (color = 'primary', intensity: Intensity = Intensity.default, shade = 0) => {
  const { a, b } = backgroundColors[color]
  const { ia, ib } = {
    strong: { ia: '.85', ib: '.85' },
    default: { ia: '.5', ib: '.5' },
    soft: { ia: '.20', ib: '.20' }
  }[intensity || 'default']

  const bgA = RGB_Linear_Shade(shade, `rgba(${hexToRgb(a).split(' ').join('')},${ia})`)
  const bgB = RGB_Linear_Shade(shade, `rgba(${hexToRgb(b).split(' ').join('')},${ib})`)
  return css` 
    z-index: 2;
    &:before {
      background: rgba(${hexToRgb(blackColor)}, 0.5);
    }
    &:after {
      background: linear-gradient(60deg,${bgA},${bgB});
    }
    &:after,&:before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      content: '';
    }
  `
}