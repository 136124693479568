import { grayColor, logoColor } from 'components/styles/constants';
import styled, { css } from 'styled-components';


export const BubbleStyled = styled.button`
  font-family: "BubblePeanut";
  font-size: 2rem;
  color: ${logoColor};
  background: transparent;

  border: 0;

  z-index: 2;
  
  right: 1rem;
  padding: 0.5rem;

  display: block;
  margin: 4rem auto;

  ${({ absolute }) => absolute && css`
    position: absolute;
    margin: -4rem 0 0 0;
  `}

  &:before {
    content: '';
    background: #4646aa;
    opacity: 0.15;
    width: 15.5rem;
    height: 3rem;
    display: block;
    position: absolute;
    border-radius: 1rem;
    margin: -0.5rem 0 0 -0.5rem;
  }
`
export const TextNameStyled = styled.p`
  font-style: italic;
  text-align: right;
`

export const PresentationPageWrapper = styled.div`
  width: 100%;
  display: block;
`