import React, { FC } from 'react'
import { Grid } from '@material-ui/core'
import { GridStyled, HeroWrapper } from './styled'
import { Color, IChildren } from 'components/styles/interface'

export type HeroIntensity =
  | ''
  | 'soft'
  | 'strong'

export interface IHeroProps extends IChildren {
  color?: Color;
  intensity?: HeroIntensity;
  shade?: Number;
  image?: string;
  className?: string;
}

const Hero: FC<IHeroProps> = ({
  color,
  intensity,
  shade,
  image,
  className,
  children
}) => {
  return (
    <HeroWrapper className={className} color={color} intensity={intensity} shade={shade} image={image}>
      <GridStyled spacing={4}>
        <Grid item sm={12}>{children}</Grid>
      </GridStyled> 
    </HeroWrapper>
  )
}

export default Hero