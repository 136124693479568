import BubbleTTF from 'assets/fonts/BubblePeanutRegular.ttf'
import {
  createGlobalStyle
} from "styled-components";


export const FontStyled = createGlobalStyle`
@font-face {
font-family: 'BubblePeanut';
src: url(${BubbleTTF}) format("truetype");
}
`;
export const HelmetGlobalStyled: any = `
body {
  margin: 0;
  overflow-x: hidden;
}
svg {
  fill: currentColor;
}
.linear-status {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1204;
}
`;

