import { HelmetGlobalStyled, FontStyled } from 'components/styles/global-styled';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
 
interface IProps {
  description?: string;
  lang?: string;
  meta?: any;
  title: string;
};
 
const Seo: FC<IProps> = ({
  description,
  lang,
  meta = [],
  title,
  children,
}) => {
 
  return (
  <>
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    >
      <style type="text/css">
        {HelmetGlobalStyled}
      </style>
      { children }
    </Helmet>
    <FontStyled />
  </>
  );
}


export default Seo;



 