import logo from 'assets/img2/logo.png'
import rawLogo from 'assets/img2/logo-solo.png'
import bingo from 'assets/img2/bg-bingo.jpg'
import butterfly from 'assets/img2/bg-butterfly.jpg'
import caterpillarEmpty from 'assets/img2/bg-caterpillar-empty.jpg'
import caterpillarGreen from 'assets/img2/bg-caterpillar-green.jpg'
import coffeeLow from 'assets/img2/bg-coffee-low.jpg'
import coffee from 'assets/img2/bg-coffee.jpg'
import equalLow from 'assets/img2/bg-equal-low.jpg'
import equal from 'assets/img2/bg-equal.jpg'
import handsPurpleLow from 'assets/img2/bg-hands-purple-low.jpg'
import handsPurple from 'assets/img2/bg-hands-purple.jpg'
import hands from 'assets/img2/bg-hands.jpg'
import lemon from 'assets/img2/bg-lemon.jpg'
import loveMe from 'assets/img2/bg-love-me.jpg'
import notebook from 'assets/img2/bg-notebook.jpg'
import peopleLow from 'assets/img2/bg-people-low.jpg'
import people from 'assets/img2/bg-people.jpg'
import pinypon from 'assets/img2/bg-pinypon.jpg'
import planLow from 'assets/img2/bg-plan-low.jpg'
import plan from 'assets/img2/bg-plan.jpg'
import purple from 'assets/img2/bg-purple.jpg'
import startLow from 'assets/img2/bg-road-start-low.jpg'
import start from 'assets/img2/bg-road-start.jpg'
import ropeLow from 'assets/img2/bg-rope-low.jpg'
import rope from 'assets/img2/bg-rope.jpg'
import socialLow from 'assets/img2/bg-social-low.jpg'
import social from 'assets/img2/bg-social.png'
import waterHi from 'assets/img2/bg-water-hi.jpg'
import water from 'assets/img2/bg-water.jpg'
import wheelLow from 'assets/img2/bg-wheel-low.jpg'
import wheel from 'assets/img2/bg-wheel.jpg'
import rJovenes from 'assets/img2/media-radio-jovenes.jpg'
import iDiagnostico from 'assets/img2/media-insta-diagnostico.jpg'
import iEntrevista from 'assets/img2/media-insta-entrevista.jpg'
import iJovenes from 'assets/img2/media-insta-jovenes.jpg'
import iOir from 'assets/img2/media-insta-oir.jpg'
import iServicios from 'assets/img2/media-insta-servicios.jpg'
import space from 'assets/img2/media-insta-servicios.jpg'

import fastEmail from 'assets/img2/streamline-icon-fast-email@400x400.png'

export default {
  logo,
  rawLogo,
  pic: {
    fastEmail
  },
  media: {
    iDiagnostico,
    iEntrevista,
    iJovenes,
    iOir,
    iServicios,
    rJovenes
  },
  bg: {
    bingo,
    butterfly,
    caterpillarEmpty,
    caterpillarGreen,
    coffee,
    coffeeLow,
    equal,
    equalLow,
    hands,
    handsPurple,
    handsPurpleLow,
    lemon,
    loveMe,
    notebook,
    people,
    peopleLow,
    pinypon,
    plan,
    planLow,
    purple,
    space,
    start,
    startLow,
    rope,
    ropeLow,
    social,
    socialLow,
    water,
    waterHi,
    wheel,
    wheelLow
  },
  video: {
  }
}

