import styled, { css } from 'styled-components';
import { grayColor, whiteColor } from 'components/styles/constants';
import Hero from 'components/core/Hero/Hero';
import { List, ListItem } from '@material-ui/core';
import { Link } from 'gatsby';
import { Logo } from 'views/Logo/Logo';
import FadeBlock from 'components/core/FadeBlock/FadeBlock';
import { Title2 } from 'components/core/Typography/title';
import { Text1 } from 'components/core/Typography/text';

export const ArrowStyled = styled.button`
  &.slick-arrow.slick-next, &.slick-arrow.slick-prev {
    background: black;
    height: 3rem;
    width: 3rem;
    border-radius: 75%;
    top: 75%;
  }
`
export const BrandStyled = styled.h1`
  color: ${whiteColor};
  text-transform: initial;
`
export const FadeBlockStyled = styled(FadeBlock)`
  width: 100%;
  ${({ theme }) => css`
    ${theme.breakpoints.up('sm')} {
      width: 80%;
    }
  `}
`
export const FadeBlockRightStyled = styled(FadeBlockStyled)`
  float: right;
`

export const HeroStyled = styled(Hero)`
  height: ${({ height }) => height || 80}vh;

  ${({ round }) => round && css`
    clip-path: ellipse(45% 40% at 50% 40%);
  `}

  width: 100%;

  &:before {
    display: none;
  }
  & h1 {
    font-size: 4rem;
    font-weight: bold;
    color: ${grayColor[1]};
  }
  & h4 {
    font-size: 2rem;
    color: ${grayColor[1]};
  }

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      & h4 {
        font-size: 1.4rem;
      }
    }
  `}
  /* &:before {
    content: ' ';
    position: absolute;
    width: 100%;
    left: 0;
    top: 57vh;
    background: transparent;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0wIDE0MGgxMjgwQzU3My4wOCAxNDAgMCAwIDAgMHoiIGZpbGwtb3BhY2l0eT0iLjMiLz48cGF0aCBkPSJNMCAxNDBoMTI4MEM1NzMuMDggMTQwIDAgMzAgMCAzMHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMCAxNDBoMTI4MEM1NzMuMDggMTQwIDAgNjAgMCA2MHoiLz48L2c+PC9zdmc+);
    height: 18vh;
    z-index: 3;
  } */
`

export const LinkStyled = styled(Link)`
  color: inherit;
  display: block;
  padding: 0.6rem 0.9375rem;
  position: relative;
  font-size: 12px;
  font-weight: 500;
  border-radius: 3px;
  text-transform: uppercase;
  text-decoration: none;
`
export const ListStyled = styled(List)`
  &.MuiList-root {
    color: #3c4858;
    margin: 0 auto;
  }
`
export const ListItemStyled = styled(ListItem)`
  &.MuiListItem-root {
    width: auto;
    display: inline-block;
    padding: 0;
  } 
`
export const LinksWrapperStyled = styled.nav`
  display: flex;
  padding: 1rem;
  margin: 0 auto;
  z-index: 2;
  position: relative;
  text-align: center;
  width: 100%;
  background: transparent;
  color: ${whiteColor};

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      flex-direction: column;
    }
  `}
`
export const LogoHeaderStyled = styled(Logo)`
  position: absolute;
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      position: relative;
    }
  `}

  & svg, img {
    height: 3rem;
  }
  & h1 {
    color: inherit;
    font-size: 1.4rem;
  }
`
export const LogoHeroStyled = styled(Logo)`
  position: relative;
  & svg, img {
    height: 6rem;
  }
  & h1 {
    color: inherit;
    font-weight: normal;
  }
  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      & svg, img {
        height: 4rem;
      }
      & h1 {
        font-size: 2rem;
      }
    }
  `}
`

export const MyTitle = styled.h4`
  font-size: 1.3rem;
  font-family: "Roboto Slab", "Times New Roman", serif;
  text-decoration: none;
  font-weight: 700;

  margin-top: .625rem;
  margin-bottom: 0.75rem;
  min-height: auto;
`

export const SectionWrapper = styled.div`
  padding: 3rem 1rem;
  color: ${grayColor[0]};

  ${({ theme }) => css`
    ${theme.breakpoints.down('xs')} {
      padding: 3rem 0rem;
    }
  `}
`
export const Text1Styled = styled(Text1)`
  text-align: center;
  height: 4rem;
`
export const TitleStyled = styled(Title2).attrs(({
  weight: 'bold'
}))`
  margin: 2rem 0 1rem;
`
export const ListBulletStyled = styled.ul`
  text-align: left;
  list-style: ${({ number }) => number ? 'auto' : 'circle'};

`