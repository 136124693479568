import React, { FC, memo } from 'react';

import {
  LinkLogo,
  LogoLeftStyled,
  LogoWrapper,
} from './styled';
import images from 'components/styles/images';
import { Button } from '@material-ui/core';
import { BrandStyled } from 'views/PresentationPage/Sections/styled';

interface IProps {
  href?: string;
  className?: string;
}
export const LogoMemo: FC<IProps> = ({
  href = '/',
  className
}) => {
  return (
    <LinkLogo to={href} className={className}>
      <Button color="inherit">
        <LogoWrapper>
          <LogoLeftStyled>
            <img src={images.rawLogo} />
          </LogoLeftStyled>
          <BrandStyled>AlianZas</BrandStyled>
        </LogoWrapper>
      </Button>
    </LinkLogo>
  );
};

export const Logo = memo(LogoMemo);