import RegularButton from './Button';
import { twitterColor } from 'components/styles/constants';
import { Title4, Title5 } from 'components/core/Typography/title';
import styled, { css } from 'styled-components';

export const GenericButton = styled(RegularButton)`
  font-size: 1.125rem;
`
export const PrimaryButton = styled(GenericButton).attrs(() => ({
  variant: 'contained',
  color: 'primary'
}))`
`
export const PrimaryEmptyButton = styled(GenericButton).attrs(() => ({
  variant: 'outlined',
  color: 'primary'
}))`
  text-transform: none; 
`
export const SecondaryButton = styled(GenericButton).attrs(() => ({
  variant: 'contained',
  color: 'secondary'
}))`
`
export const SecondaryEmptyButton = styled(GenericButton).attrs(() => ({
  variant: 'outlined',
  color: 'secondary'
}))`
  text-transform: none; 
`

export const TransparentButton = styled(GenericButton).attrs(() => ({
  color: 'primary'
}))`
  text-transform: none;
  color: ${twitterColor};
`
