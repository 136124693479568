import React, { FC } from 'react'
import { Color, IChildren, Transition } from 'components/styles/interface'
import { ContainerStyled, FadeBlockWrapper } from 'components/core/FadeBlock/styled'
import { Fade, Slide } from '@material-ui/core'


export interface IProps extends IChildren {
  color?: Color;
  checked: boolean;
  transition: Transition;
  className?: string;
}

const FadeBlock: FC<IProps> = ({
  color,
  checked,
  transition,
  className,
  children
}) => (
  <Slide direction="up" in={checked} mountOnEnter unmountOnExit>
    <FadeBlockWrapper className={className} color={color} transition={transition}>
      <ContainerStyled>
        { children }
      </ContainerStyled>
    </FadeBlockWrapper>
  </Slide>
)

export default FadeBlock