import React, { useEffect } from "react";

import "assets/scss/material-kit-pro-react.scss?v=1.8.0";
import "animate.css/animate.min.css";
import { withTheme } from "setup/withTheme";
import Seo from "components/core/Seo/Seo";

export default ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  return (
    <>
      <Seo lang='ES'
        title="AlianZas"
        description='Cuidado y cambio social.'>
        <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Roboto+Slab:400,700|Material+Icons"/>
        <link href="https://use.fontawesome.com/releases/v5.0.10/css/all.css" rel="stylesheet"/>
        <link href="https://use.fontawesome.com/releases/v5.0.10/css/all.css" rel="stylesheet"/>
      </Seo>
      {withTheme(
        <>
        { children }
        </>
      )}
    </>
  )
}
